import * as React from 'react';
import { graphql, type PageProps } from 'gatsby';
import { useEffect, useReducer, useState } from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import Layout from '../../components/layout';
import ShopSearchBanner from '../../components/shop-search-banner/shop-search-banner';
import PromotedShops from '../../components/promoted-shops/promoted-shops';
import ShopItemsGrid from '../../components/shops/shop-items-grid/shop-items-grid';
import SEO from '../../components/shared/seo/seo';
import { getOppositeLanguage, replaceParamInTranslation as replaceParam } from '../../utils/i18n-utils';
import { TrooperQueryParam } from '../../utils/query-params-const';
import { GoToShopModalDispatchContext } from '../../contexts/go-to-shop-modal-context';
import GoToShopModal from '../../components/shared/modal/go-to-shop-modal/go-to-shop-modal';
import { goToShopModalReducer, type GoToShopModalState } from '../../state/go-to-shop-modal/go-to-shop-modal-state';
import { ShopSearchContext } from '../../contexts/shop-search-context';
import { shopSearchReducer } from '../../state/shop-search/shop-search-state';
import * as styles from './association.module.scss';
import { TROOPER_PATHS } from '../../../constants';
import { associationPageNameCookieKey, hideRedirectAlertCookieKey } from '../../utils/cookie-helper';
import AssociationBanner from '../../components/associations/association-banner/association-banner';
import Alert from '../../components/shared/alert/alert';
import { CookiesUtils } from '../../utils/cookies-utils';
import Modal from '../../components/shared/modal/modal';
import { LocalStorageKeys, LocalStorageUtils } from '../../utils/local-storage-utils';

declare let gtag: Function;

function AssociationPage({ data, location }: PageProps<Queries.AssociationPageQuery>) {
  const { t, language } = useI18next();
  const [goToShopModalState, goToShopModalDispatch] = useReducer(goToShopModalReducer, {
    isOpened: false,
  } as GoToShopModalState);

  const [shopSearchState, shopSearchDispatch] = useReducer(shopSearchReducer, {
    categoryFilter: '',
    nameFilter: new URLSearchParams(location.search).get(TrooperQueryParam.ShopSearchTerm) ?? '',
    showPromotedShops: !new URLSearchParams(location.search).has(TrooperQueryParam.ShopSearchTerm),
  });

  const { association } = data;

  if (!association || !association.pageName) {
    return null;
  }

  CookiesUtils.saveCookie(associationPageNameCookieKey, association.pageName as string, true);

  const oppositeLanguage = getOppositeLanguage(language);
  const cleanPageName = encodeURIComponent(association.pageName.trim());
  const otherLanguagePath = `/${oppositeLanguage}/${TROOPER_PATHS[oppositeLanguage].association}/${cleanPageName}`;

  const hasFeedbackParam = new URLSearchParams(location.search).has('askFeedback');
  const hideRedirectAlert = CookiesUtils.readCookie(hideRedirectAlertCookieKey) === 'true';

  const [showAlert, setShowAlert] = useState(hasFeedbackParam && !hideRedirectAlert);

  const hideRedirectInfoModal = LocalStorageUtils.getItem(LocalStorageKeys.RedirectToAssociationInfoClosed) === 'true';
  const [showRedirectInfoModal, setShowRedirectInfoModal] = useState(hasFeedbackParam && !hideRedirectInfoModal);

  const addGtags = () => {
    if (typeof gtag === typeof Function) {
      gtag('event', 'conversion', {
        allow_custom_scripts: true,
        u3: association?.pageName,
        send_to: `${process.env.FLOODLIGHTID}/veren0/veren0+standard`,
      });
    }
  };

  const handleCloseAlert = () => {
    CookiesUtils.saveCookie(hideRedirectAlertCookieKey, 'true', true);
    setShowAlert(false);
  };

  const handleCloseRedirectInfoModal = () => {
    LocalStorageUtils.setItem(LocalStorageKeys.RedirectToAssociationInfoClosed, 'true');
    setShowRedirectInfoModal(false);
  };

  useEffect(() => {
    addGtags();
  }, []);

  return (
    <Layout otherLanguagePath={otherLanguagePath}>
      <Modal isOpen={showRedirectInfoModal} hasCloseBtn onClose={handleCloseRedirectInfoModal}>
        <div className={styles.redirectInfoModal}>
          <p>{t('Association_RedirectInfo')}</p>
          <button onClick={handleCloseRedirectInfoModal} className="btn btn--orange" type="button">
            {t('Association_RedirectInfoButton')}
          </button>
        </div>
      </Modal>

      {showAlert && (
        <Alert
          message={replaceParam(t('Association_AskFeedback'), 'association', association?.name!)}
          onClose={handleCloseAlert}
        />
      )}
      <AssociationBanner data={data} />

      <GoToShopModalDispatchContext.Provider value={goToShopModalDispatch}>
        {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
        <ShopSearchContext.Provider value={{ state: shopSearchState, dispatch: shopSearchDispatch }}>
          <ShopSearchBanner associationName={association?.name} excludedShopIds={association?.excludedShopIds} />

          <section>
            <div className={styles.container}>
              {shopSearchState.showPromotedShops ? (
                <PromotedShops
                  associationId={association?.associationId}
                  associationName={association?.name}
                />
              ) : null}
              <ShopItemsGrid excludedShopIds={association?.excludedShopIds} />
            </div>
          </section>
        </ShopSearchContext.Provider>
        {goToShopModalState.isOpened ? (
          <GoToShopModal
            shop={goToShopModalState.shop}
            association={association}
            isOpen={goToShopModalState.isOpened}
          />
        ) : null}
      </GoToShopModalDispatchContext.Provider>
    </Layout>
  );
}

export const query = graphql`
    query AssociationPage($pageName: String!, $language: String!) {
    association(pageName: { eq : $pageName }) {
        associationId
        name
        croppedLogoUrl
        slogan
        goal
        commissionSum
        pageName
        excludedShopIds
        earningSummary {
            total
            accepted
            pending
            rejected
            confirmed
        }
  }

  locales: allLocale(filter: {ns: {in: [
      "Association",
      "Search",
      "Association_SearchPlaceholders",
      "Association_ShopPopup",
      "Association_ShopCookiePopup",
      "Association_GoalSplitPopup",
      "Metadescriptions_Association_Google",
      "Metadescriptions_Association_Og",
      "CrowdsellingBanner"
    ]}, language: {eq: $language}}) {
    edges {
        node {
            ns
            data
            language
        }
    }
}
}
`;

export function Head({ data, location }: PageProps<Queries.AssociationPageQuery>) {
  // https://github.com/gatsbyjs/gatsby/issues/36458
  const { association } = data;

  const dataLanguage = data.locales.edges
    .filter((e) => e.node.ns === 'Metadescriptions_Association_Google' || e.node.ns === 'Metadescriptions_Association_Og')
    .map((n) => n.node.data);

  const t = {
    // @ts-expect-error
    ...JSON.parse(dataLanguage[0]),
    // @ts-expect-error
    ...JSON.parse(dataLanguage[1]),
  };

  return (
    <SEO
      image="https://trooperv2.blob.core.windows.net/staticassets/og_association.jpg"
      title={replaceParam(t.Metadescriptions_Association_Google_Title, 'association', association?.name!)}
      ogTitle={replaceParam(t.Metadescriptions_Association_Og_Title, 'association', association?.name!)}
      description={replaceParam(t.Metadescriptions_Association_Google_Description, 'association', association?.name!)}
      ogDescription={replaceParam(t.Metadescriptions_Association_Og_Description, 'association', association?.name!)}
      pathname={location.pathname}
    />
  );
}

export default AssociationPage;
